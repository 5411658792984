import { useCallback, useMemo, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import toNumber from 'lodash/toNumber';
import map from 'lodash/map';

import { UserClient, UserID } from '../../../../../../users/usersTypes';

import {
  SmartContractShareIteration,
  SmartContractShareShareType,
  SmartContractShareStatus
} from '../../../../../smartContractSharesTypes';
import {
  CreateSmartContractShareFormData,
  CreateSmartContractShareFormUser,
  CreateSmartContractShareFormView
} from '../../CreateSmartContractShareForm.types';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

export type CreateSmartContractShareFormDefaultUser =
  CreateSmartContractShareFormUser & { client?: UserClient };

export type CreateSmartContractShareFormSubmitUser =
  CreateSmartContractShareFormUser & { status?: SmartContractShareStatus };

interface SmartContractSharesFormOptions {
  iteration?: SmartContractShareIteration;
  status: SmartContractShareStatus;
  defaultUsers?: CreateSmartContractShareFormDefaultUser[];
  onSubmit: (users: CreateSmartContractShareFormSubmitUser[]) => void;
}

type CheckCreatedUser = { success: UserID[]; failed: UserID[] };

const emptyUserIds: CheckCreatedUser = { success: [], failed: [] };

const createSmartContractShareFormDefaultValues: CreateSmartContractShareFormData =
  {
    users: {
      clients: [],
      workers: []
    },
    iteration: '',
    withoutEventIds: []
  };

function useSmartContractSharesForm({
  iteration,
  status,
  defaultUsers,
  onSubmit
}: SmartContractSharesFormOptions) {
  const checkCreatedUser = useRef<CheckCreatedUser>(emptyUserIds);

  const isEmptyDefaultUsers = isEmpty(defaultUsers);
  const defaultValues = useMemo<CreateSmartContractShareFormData>(() => {
    const defaultIteration = iteration ? iteration.toString() : '';

    return isEmptyDefaultUsers
      ? {
          ...createSmartContractShareFormDefaultValues,
          iteration: defaultIteration
        }
      : {
          users: {
            clients: map(
              defaultUsers.filter((user) => user.client),
              (user) => ({
                userId: user.id,
                nanoId: user.nanoId,
                share: user.share || 0,
                shareType: user.shareType,
                generalLedgerId: user.generalLedgerId,
                fullName: user.fullName,
                image: user.image,
                currentTeam: user.currentTeam
              })
            ),
            workers: map(
              defaultUsers.filter((user) => !user.client),
              (user) => ({
                userId: user.id,
                nanoId: user.nanoId,
                share: user.share || 0,
                shareType: user.shareType,
                generalLedgerId: user.generalLedgerId,
                fullName: user.fullName,
                image: user.image,
                currentTeam: user.currentTeam
              })
            )
          },
          iteration: defaultIteration,
          withoutEventIds:
            createSmartContractShareFormDefaultValues.withoutEventIds
        };
  }, [defaultUsers, isEmptyDefaultUsers, iteration]);

  const [formView, setFormView] = useState(
    CreateSmartContractShareFormView.SELECT_USERS
  );

  const {
    handleSubmitReactHookForm,
    control,
    resetForm,
    watch,
    setValue,
    getValues
  } = useReactHookForm<CreateSmartContractShareFormData>({
    defaultValues,
    isModalForm: true
  });

  const handleResetSmartContractSharesForm = useCallback<() => void>(() => {
    checkCreatedUser.current.failed = [];
    checkCreatedUser.current.success = [];
    resetForm(defaultValues);
    setFormView(CreateSmartContractShareFormView.SELECT_USERS);
  }, [defaultValues, resetForm]);

  const goToChangeShareView = useCallback(async () => {
    setFormView(CreateSmartContractShareFormView.CHANGE_SHARE);
  }, []);

  const goToSelectUsersView = useCallback(async () => {
    setFormView(CreateSmartContractShareFormView.SELECT_USERS);
  }, []);

  const watchUsers = watch('users');

  const handleSubmitSmartContractShares = useCallback(() => {
    if (
      formView === CreateSmartContractShareFormView.CHANGE_SHARE ||
      (formView === CreateSmartContractShareFormView.SELECT_USERS &&
        isEmpty(watchUsers.clients) &&
        isEmpty(watchUsers.workers))
    ) {
      return handleSubmitReactHookForm({
        onSubmit: (data: CreateSmartContractShareFormData) => {
          if (isEmpty(data.users.clients) && isEmpty(data.users.workers)) {
            return onSubmit([]);
          }

          const workers = map(data.users.workers, (user) => ({
            id: user.userId,
            nanoId: user.nanoId,
            generalLedgerId: user.generalLedgerId,
            fullName: user.fullName,
            image: user.image,
            currentTeam: user.currentTeam,
            shareType: user.shareType as SmartContractShareShareType,
            share: user.share,
            iteration: data.iteration ? toNumber(data.iteration) : null,
            status
          }));

          const clients = map(data.users.clients, (user) => ({
            id: user.userId,
            nanoId: user.nanoId,
            generalLedgerId: user.generalLedgerId,
            fullName: user.fullName,
            image: user.image,
            currentTeam: user.currentTeam,
            shareType: user.shareType as SmartContractShareShareType,
            client: true,
            share: user.share,
            iteration: data.iteration ? toNumber(data.iteration) : null,
            status
          }));

          return onSubmit(concat(clients, workers));
        },
        dirtyFieldsOnly: false
      })();
    }

    return goToChangeShareView();
  }, [
    formView,
    goToChangeShareView,
    handleSubmitReactHookForm,
    onSubmit,
    status,
    watchUsers
  ]);

  return {
    control,
    handleResetSmartContractSharesForm,
    handleSubmitSmartContractShares,
    setValue,
    formView,
    goToChangeShareView,
    goToSelectUsersView,
    getValues,
    notHideOnSubmit:
      formView === CreateSmartContractShareFormView.SELECT_USERS &&
      !(isEmpty(watchUsers.clients) && isEmpty(watchUsers.workers))
  };
}

export default useSmartContractSharesForm;
