import { Control, Controller } from 'react-hook-form';
import head from 'lodash/head';

import { IsDisabled } from '../../../../../../../../../types';

import { CreateProjectInTeamFormData } from '../../../../CreateProjectInTeamForm.types';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { SmartContractShareStatuses } from '../../../../../../../../smartContractShares/smartContractSharesTypes';

import { SmartContractShareFormModalButton } from '../../../../../../../../smartContractShares/components/modalButtons/SmartContractShareFormModalButton';
import { CreateProjectInTeamFormTasksListItemSelectedUsers } from '../CreateProjectInTeamFormTasksListItemSelectedUsers';

import { Translate } from '../../../../../../../../../helpers/Translate';

import { tasksKeys } from '../../../../../../../../../locales/keys';

interface CreateProjectInTeamFormTasksListItemUsersFieldProps {
  control: Control<CreateProjectInTeamFormData>;
  disabled?: IsDisabled;
  index: number;
}

function CreateProjectInTeamFormTasksListItemUsersField({
  control,
  disabled,
  index
}: CreateProjectInTeamFormTasksListItemUsersFieldProps) {
  return (
    <Controller
      control={control}
      name={`tasks.${index}.users`}
      render={({ field: { onChange, value } }) => (
        <div>
          <div className="flex items-center gap-2">
            <div className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              <Translate id={tasksKeys.invitations} />
            </div>

            {/* button */}
            <SmartContractShareFormModalButton
              className="py-0.5 pl-0.5 pr-0.5 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
              icon={IconsEnum.PLUS}
              iconClassName="h-5 w-5"
              defaultUsers={value}
              onSubmit={onChange}
              status={SmartContractShareStatuses.INVITED}
              disabled={disabled}
              iteration={head(value)?.iteration}
            />
          </div>

          {/* users */}
          <div className="mt-1">
            <CreateProjectInTeamFormTasksListItemSelectedUsers
              users={value}
              onRemoveUser={(id) =>
                onChange(value?.filter((user) => user.id !== id))
              }
              onRemoveAllUsers={() => onChange([])}
              disabled={disabled}
            />
          </div>
        </div>
      )}
    />
  );
}

export default CreateProjectInTeamFormTasksListItemUsersField;
